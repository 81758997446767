import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import styled from "styled-components"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

const HeaderSection = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: auto;
  flex-flow: row;
  background-color: rgba(30, 139, 195, 1);
`

const HeaderText = styled.div`
  border: 0px solid green;
  font-family: montserrat;
  width: 100%;
`
const List = styled.ul`
  margin: 0;
  padding: 1rem;
  color: white;
  background-color: (30, 139, 195, 1);
`

const BreadCrumb = styled.li`
  display: inline;
  padding: 1rem;
`
const BreadCrumbLink = styled(Link)`
  color: white;
  text-align: center;
  padding: 24px 16px;
  color: #ccc;
  text-decoration: none;
`
const PanelRow = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-flow: column;
    padding: 1rem;
  }
  @media (min-width: 768px) {
    flex-flow: row;
    padding: 5rem;
  }
`

const ContactContainer = styled.div`
  border-radius: 5px;
  background-color: #f2f2f2;
  width: 100%;
  color: black;
  @media (max-width: 768px) {
    padding: 10px;
  }
  @media (min-width: 768px) {
    padding: 20px;
  }
`

const IndexPage = () => (
  <Layout>
    <HeaderSection>
      <HeaderText>
        <List>
          <BreadCrumb>
            <span>
              <BreadCrumbLink to="/">Home </BreadCrumbLink>
            </span>

            <span>Get startedd</span>
          </BreadCrumb>
        </List>
      </HeaderText>
    </HeaderSection>
    <div
      style={{
        color: "black",
        paddingLeft: "4rem",
        paddingRight: "4rem",
        textAlign: "center",
        marginTop: "4rem",
        fontFamily: "montserrat",
      }}
    >
      <h1>Inflowkit </h1>
      <a
        href="https://app.inflowkit.com/creators"
        style={{ textAlign: "center", fontWeight: "bold", fontSize: "2rem" }}
        target="_blank"
      >
        {" "}
        <img src="/logo.png" width="20%" />
      </a>
      <hr />

      <h1>Shopify app</h1>
      <a
        href="https://app.inflowkit.com/shopify_app"
        style={{ textAlign: "center", fontWeight: "bold", fontSize: "2rem" }}
        target="_blank"
      >
        {" "}
        <img src="/shopify.png" width="20%" />
      </a>
      <hr />
      <h1>coming soon!</h1>
      <a
        onClick={()=>{
          alert("Woo commerce integration coming soon!")
        }}
        style={{ textAlign: "center", fontWeight: "bold", fontSize: "2rem" }}
        target="_blank"
      >
        {" "}
        <img src="/woocommerce_logo.png" width="20%" />
      </a>
    </div>
  </Layout>
)

export default IndexPage
